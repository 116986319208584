export default {
  handleError(error) {
    let message = ""; //;

    if (typeof error !== "undefined") {
      if (Object.prototype.hasOwnProperty.call(error, "message")) {
        message = error.message;
      }
    }

    if (typeof error.response !== "undefined") {
      //Setup Generic Response Messages
      if (error.response.status === 401) {
        message = "Sin autorización";
      } else if (error.response.status === 403) {
        message =
          "Su usuario no tiene permisos para acceder a esta aplicación.\n Consulte con el administrador del sistema.";
      } else if (error.response.status === 404) {
        message = "La ruta de la API no existe";
      } else if (error.response.status === 405) {
        message = "La routa de la API no está permitida";
      } else if (error.response.status === 422) {
        message = "Error de Validación";
      } else if (error.response.status >= 500) {
        message = "Error de Servidor";
      }

      //Try to Use the Response Message
      if (
        Object.prototype.hasOwnProperty.call(error, "response") &&
        Object.prototype.hasOwnProperty.call(error, "data")
      ) {
        if (
          Object.prototype.hasOwnProperty.call(error, "message") &&
          error.response.data.message.length > 0
        ) {
          message = error.response.data.message;
        }
      } else if (error.response.data[0]) message = error.response.data[0];
      else if (
        error.response.data.non_field_errors &&
        error.response.data.non_field_errors[0]
      )
        message = error.response.data.non_field_errors[0];
    }
    return message;
  },
};
